<template>
  <div>
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3777.122862234605!2d98.99036541489683!3d18.79267718725106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3bb3e5a1afcf%3A0xea0de381fe230801!2sSHAN%20BOUTIQUE%20HOTEL!5e0!3m2!1sth!2sth!4v1652609366918!5m2!1sth!2sth"
        style="border:0;" allowfullscreen="" loading="lazy" class="p-3"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    <div class="contact p-3">
      <p>
        <b-icon-geo-alt-fill/>
        9/2 Soi 7 MoonMuang Road, Sripoom, Muang, Chiangmai, Thailand 50200
      </p>
      <ul>
        <b-icon-telephone-fill/>
        <a :href="'tel:' + '+6653222100'">+66 53-222-100</a></ul>
      <ul>
        <b-icon-telephone-fill/>
        <a :href="'tel:' + '+66811409406'">+66 81-140-9406</a></ul>
      <ul>
        <b-icon-envelope-fill/>
        <a :href="'mailto:' + 'shanboutiquehotel@gmail.com'">shanboutiquehotel@gmail.com</a></ul>
      <ul>
        <b-icon-facebook/>
        <a href="https://www.facebook.com/CMapartmentCom">Shan Boutique Hotel</a></ul>
      <ul>
        <img :src="line"/>
        <a href="#">Coming Soon...</a></ul>
    </div>
  </div>
</template>

<script>
import {getStorage, ref, getDownloadURL} from "firebase/storage";
import {BIconTelephoneFill, BIconEnvelopeFill, BIconFacebook, BIconGeoAltFill} from 'bootstrap-vue'

export default {
  components: {
    BIconTelephoneFill,
    BIconEnvelopeFill,
    BIconFacebook,
    BIconGeoAltFill
  },
  data() {
    return {
      line: ""
    };
  },
  mounted() {
    const firebaseApp = firebaseApp;
    getDownloadURL(ref(getStorage(firebaseApp), "line.png")).then(
        (url) => {
          this.line = url;
        }
    );
  }
}
</script>

<style scoped>
iframe {
  /*height: 30vw;*/
  height: 350px;
  width: 80vw;
}

.contact {
  background-color: #eeeeee;
}

a {
  margin-left: 5px;
  margin-right: 20px;
}

ul {
  display: inline-block;
}

img {
  height: 22px;
}
</style>